export const listFields = [
    {
        name: 'actinobacteria',
        color: 'rgb(187, 82, 138)'
    },
    {
        name: 'firmicutes',
        color: 'rgb(176, 122, 169)'
    },
    {
        name: 'bacteroidetes',
        color: 'rgb(199, 157, 187)'
    },
    {
        name: 'proteobacteria',
        color: 'rgb(224, 192, 205)'
    },
    {
        name: 'other',
        color: 'rgb(83, 102, 255)'
    },
    {
        name: 'cyanobacteria',
        color: 'rgb(217, 154, 113)'
    },
    {
        name: 'fusobacteriota',
        color: 'rgb(111, 199, 177)'
    },
    {
        name: 'patescibacteria',
        color: 'rgb(183, 201, 94)'
    },
    {
        name: 'desulfobacterota',
        color: 'rgb(83, 102, 255)'
    },
    {
        name: 'spirochaetota',
        color: 'rgb(255, 99, 132)'
    },
    {
        name: 'synergistota',
        color: 'rgb(54, 162, 235)'
    }
];

export const barColors = {
    Actinobacteriota: 'rgba(187, 82, 138, 0.5)',
    Firmicutes: 'rgba(176, 122, 169, 0.5)',
    Bacteroidetes:'rgba(199, 157, 187,0.5)',
    Proteobacteria: 'rgba(224, 192, 205, 0.5)',
    Cyanobacteria: 'rgba(217, 154, 113, 0.5)',
    Bacteroidota: 'rgba(153, 102, 255, 0.5)',
    Fusobacteriota: 'rgba(111, 199, 177, 0.5)',
    Patescibacteria: 'rgba(183, 201, 94, 0.5)',
    Desulfobacterota: 'rgba(83, 102, 255, 0.5)',
    Spirochaetota: 'rgba(255, 99, 132, 0.5)',
    Synergistota: 'rgba(54, 162, 235, 0.5)',
    Deinococcota: 'rgba(255, 206, 86, 0.5)',
    Crenarchaeota: 'rgba(75, 192, 192, 0.5)',
    Acidobacteriota: 'rgba(153, 102, 255, 0.5)',
    Campylobacterota: 'rgba(255, 159, 64, 0.5)',
    Chloroflexi: 'rgba(199, 199, 199, 0.5)',
    Methylomirabilota: 'rgba(83, 102, 255, 0.5)',
    Aenigmarchaeota: 'rgba(255, 99, 132, 0.5)',
    Thermotogota: 'rgba(54, 162, 235, 0.5)',
    Gemmatimonadota: 'rgba(255, 206, 86, 0.5)',
    Verrucomicrobiota: 'rgba(75, 192, 192, 0.5)',
    Bdellovibrionota: 'rgba(153, 102, 255, 0.5)',
    Myxococcota: 'rgba(255, 159, 64, 0.5)',
    Armatimonadota: 'rgba(199, 199, 199, 0.5)',
    Calditrichota: 'rgba(83, 102, 255, 0.5)',
    Nitrospirota: 'rgba(255, 99, 132, 0.5)',
    Euryarchaeota: 'rgba(54, 162, 235, 0.5)',
    Halobacterota: 'rgba(255, 206, 86, 0.5)',
    Abditibacteriota: 'rgba(75, 192, 192, 0.5)',
    'SAR324_clade(Marine_group_B)': 'rgba(153, 102, 255, 0.5)',
    Planctomycetota: 'rgba(255, 159, 64, 0.5)',
    Latescibacterota: 'rgba(199, 199, 199, 0.5)',
    Others: 'rgba(83, 102, 255, 0.5)'
};
