import {SkinReportsProvider} from "./SkinReportsContext";
import {Outlet} from "react-router-dom";

const PatientSkinReportLayout = () => {

    return (
        <SkinReportsProvider>
            <Outlet/>
        </SkinReportsProvider>
    )
}

export default PatientSkinReportLayout;