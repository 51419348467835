import React, {useEffect, useRef, useState} from "react";
import {DiversityIndexStyle} from "./diversity-index.style";

const DiversityIndexWidget = ({num}) => {
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const [canvasWidth, setCanvasWidth] = useState(500); // Default canvas width
    const canvasHeight = 100; // Fixed height

    const drawWidget = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // Clear the canvas
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Adjust the coordinate system to start from 0.4
        const startCoord = 0.4;
        const endCoord = 1;
        const scale = canvas.width / (endCoord - startCoord);

        // Draw the line
        ctx.beginPath();
        ctx.moveTo(0, canvas.height / 2);
        ctx.lineTo(canvas.width, canvas.height / 2);
        ctx.strokeStyle = "rgb(0,0,0)";
        ctx.lineWidth = 5;
        ctx.stroke();

        // Draw the optimal range
        const optimalStart = (0.6 - startCoord) * scale;
        const optimalEnd = (0.8 - startCoord) * scale;
        ctx.beginPath();
        ctx.moveTo(optimalStart, canvas.height / 2);
        ctx.lineTo(optimalEnd, canvas.height / 2);
        ctx.strokeStyle = "pink";
        ctx.lineWidth = 6;
        ctx.stroke();

        // Draw vertical lines for optimal range marks
        ctx.beginPath();
        ctx.moveTo(optimalStart, canvas.height / 2 - 10);
        ctx.lineTo(optimalStart, canvas.height / 2 + 10);
        ctx.moveTo(optimalEnd, canvas.height / 2 - 10);
        ctx.lineTo(optimalEnd, canvas.height / 2 + 10);
        ctx.strokeStyle = "pink";
        ctx.lineWidth = 5;
        ctx.stroke();

        // Draw the user's diversity index
        const userPosition = (num - startCoord) * scale;
        ctx.beginPath();
        ctx.arc(userPosition, canvas.height / 2, 6, 0, 2 * Math.PI);
        ctx.fillStyle = "black";
        ctx.fill();

        // Draw the numbers and lines at each 0.1 point
        // ctx.font = "16px Arial";
        // ctx.fillStyle = "rgba(121,56,131,0.59)";
        // for (let i = startCoord; i <= endCoord; i += 0.1) {
        //     const xPos = (i - startCoord) * scale;
        //     ctx.beginPath();
        //     ctx.moveTo(xPos, canvas.height / 2 - 5);
        //     ctx.lineTo(xPos, canvas.height / 2 + 5);
        //     ctx.strokeStyle = "rgba(121,56,131,0.59)";
        //     ctx.lineWidth = 1;
        //     ctx.stroke();
        //     ctx.fillText(i.toFixed(1), xPos - 10, canvas.height / 2 - 10);
        // }

        // Display user's diversity index value
        ctx.fillText(num.toFixed(2), userPosition - 10, canvas.height / 2 + 20);
    };

    const handleResize = () => {
        if (containerRef.current) {
            const screenWidth = window.innerWidth;
            const newWidth = screenWidth < 1000 ? screenWidth - 20 : 500; // Adjust width below 1000px
            setCanvasWidth(newWidth);
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        drawWidget();
    }, [canvasWidth, num]);

    return (
        <DiversityIndexStyle ref={containerRef}>
            <canvas ref={canvasRef} style={{width: "100%", height: "auto"}}></canvas>
        </DiversityIndexStyle>
    );
};

export default DiversityIndexWidget;
