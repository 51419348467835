import React, {useContext} from "react";
import {Wrapper} from "./WelcomeBanner.styles";
import {UserContext} from '../../context/UserContext';
import {useTranslation} from "react-i18next";

const WelcomeBanner = () => {
    const {user} = useContext(UserContext);
    const {t} = useTranslation('common');

    if (!user) {
        return <p>{t('no_user_info')}</p>;
    }

    return (
        <Wrapper className="w-full min-h-32">
            <p>{t('hi_doctor', {name: user.firstName})}</p>
            <h3>{t('intro')}</h3>
        </Wrapper>
    )
};

export default WelcomeBanner;