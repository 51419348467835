import React, {createContext, useEffect, useState} from 'react';
import api from "../../../utils/api";
import {useParams} from "react-router-dom";

const SkinReportsContext = createContext();

const SkinReportsProvider = ({children}) => {
    const [reports, setReports] = useState([]);
    // const {patientId} = useParams();

    useEffect(() => {
    }, []);

    const getReports = async (patientId) => {
        console.log('patientId', patientId);
        if(reports.length > 0) {
            return reports;
        }
        try {
            const response = await api.get(`/report/${patientId}`);
            if (response.status !== 200) {
                throw new Error('Failed to fetch reports');
            }
            const data = await response.data;
            setReports(data['reports']);
            return data['reports'];
        } catch (error) {
            throw error;
        }
    }


    return (
        <SkinReportsContext.Provider
            value={{
                reports,
                setReports,
                getReports
            }}
        >
            {children}
        </SkinReportsContext.Provider>
    );
};

export {SkinReportsContext, SkinReportsProvider};
