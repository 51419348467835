import {GlobalStyle} from './GlobalStyle';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ProtectedRoute from './utils/ProtectedRoute';
import GuestRoute from './utils/GuestRoute';

//Pages
import JoinUsPage from './pages/JoinUsPage';
import HomePage from './pages/HomePage';
import HelpPage from './pages/HelpPage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgetPasswordPage';
import Signup1Page from './pages/Signup1Page';
import Signup2Page from './pages/Signup2Page';
import PostSignupPage from './pages/PostSignupPage';
import EmailVerificationPage from './pages/EmailVerificationPage';
import NewPatientPage from './pages/NewPatientPage';
import PatientListPage from './pages/PatientListPage';
import PatientsCabinetPage from './pages/PatientCabinet/PatientsCabinetPage';
import NewTestPage from './pages/NewTestPage';
import PdfPage from './pages/PatientCabinet/PdfPage';
import SelectPatientPage from './pages/SelectPatientPage';
import VideoAnalysisPage from './pages/VideoAnalysisPage';
import DoctorProfilePage from './pages/DoctorProfilePage';
import Layout from "./components/Layout";
import VisualAnalysis from "./pages/PatientCabinet/FileManagement/VisualAnalysis";
import FileUploadPage from "./pages/PatientCabinet/FileManagement/FileUploadPage";
import {Toaster} from "react-hot-toast";
import CompareFilesPage from "./pages/PatientCabinet/FileManagement/CompareFiles.page";
import PdfView from "./pages/PatientCabinet/PdfView";
import SuccessSentForgotPassword from "./pages/SuccessSentForgotPassword";
import ChangePasswordPage from "./pages/ChangePasswordPage";
import {CookieConsent} from "react-cookie-consent";
import FaceDetectionPage from './pages/FaceDetectionPage';
import PatientSkinReportPage from "./pages/PatientCabinet/skin-reports/PatientSkinReportPage";
import PatientSkinReportDetailPage from "./pages/PatientCabinet/skin-reports/PatientSkinReportDetail";
import PatientSkinReportLayout from "./pages/PatientCabinet/skin-reports/PatientSkinReportLayout";
import PatientSkinReportDetailSharePage from "./pages/standalone/PatientSkinReportDetailShare";
import PatientSkinReportDetailSharePageV2 from "./pages/standalone/PatientSkinReportDetailSharev2";
import PatientSkinReportDetailPageV2 from "./pages/PatientCabinet/skin-reports/PatientSkinReportDetailV2";

function App() {
    return (
        <Router>
            <GlobalStyle/>
            <CookieConsent
                location="bottom"
                cookieName="GlaciesCookies"
                style={{background: "#2B373B"}}
                buttonStyle={{color: "#4e503b", fontSize: "13px"}}
                expires={150}
            >
                This website uses cookies to enhance the user experience.{" "}
            </CookieConsent>
            <Layout>
                <Routes>
                    <Route path='/' element={<GuestRoute> <JoinUsPage/> </GuestRoute>}/>
                    <Route path='/login' element={<GuestRoute> <LoginPage/> </GuestRoute>}/>
                    <Route path='/forgot-password' element={<GuestRoute> <ForgotPasswordPage/> </GuestRoute>}/>
                    <Route path='/success' element={<GuestRoute> <SuccessSentForgotPassword/> </GuestRoute>}/>
                    <Route path='/almost-there' element={<GuestRoute> <PostSignupPage/> </GuestRoute>}/>
                    <Route path='/verify' element={<GuestRoute> <EmailVerificationPage/> </GuestRoute>}/>
                    <Route path='/sign-up' element={<GuestRoute> <Signup1Page/> </GuestRoute>}/>
                    <Route path='/sign-up-next/:role' element={<GuestRoute> <Signup2Page/> </GuestRoute>}/>
                    <Route path='/share/v1/:reportId'
                           element={<GuestRoute> <PatientSkinReportDetailSharePage/> </GuestRoute>}/>
                    <Route path='/share/:reportId'
                           element={<GuestRoute> <PatientSkinReportDetailSharePageV2/> </GuestRoute>}/>
                    <Route path="/face-detection" element={<FaceDetectionPage/>}/>
                    <Route path='/dashboard' element={<ProtectedRoute> <HomePage/> </ProtectedRoute>}/>
                    <Route path='/help-page' element={<ProtectedRoute> <HelpPage/> </ProtectedRoute>}/>
                    <Route path='/new-patient' element={<ProtectedRoute> <NewPatientPage/> </ProtectedRoute>}/>
                    <Route path='/patient-list' element={<ProtectedRoute> <PatientListPage/> </ProtectedRoute>}/>
                    <Route path='patient-cabinet/:patientId' element={<ProtectedRoute/>}>
                        <Route path='' element={<PatientsCabinetPage/>}/>
                        <Route path='skin-report' element={<PatientSkinReportLayout/>}>
                            <Route path='' element={<PatientSkinReportPage/>}/>
                            <Route path=':reportId' element={<PatientSkinReportDetailPageV2/>}/>
                            <Route path=':reportId/v2' element={<PatientSkinReportDetailPage/>}/>
                        </Route>
                        <Route path='skin-report-files'>
                            <Route path='' element={<PdfPage/>}/>
                            <Route path=':pdfid' element={<PdfView/>}/>
                        </Route>
                        <Route path='analysis'>
                            <Route path='' element={<VisualAnalysis/>}/>
                            <Route path='file-upload' element={<FileUploadPage/>}/>
                            <Route path='compare' element={<CompareFilesPage/>}/>
                        </Route>
                    </Route>
                    <Route path='/new-test' element={<ProtectedRoute> <NewTestPage/> </ProtectedRoute>}/>
                    <Route path='/pdf/:patientId' element={<ProtectedRoute> <PdfPage/> </ProtectedRoute>}/>
                    <Route path='/select-patient' element={<ProtectedRoute> <SelectPatientPage/> </ProtectedRoute>}/>
                    <Route path='/analysis' element={<ProtectedRoute> <VideoAnalysisPage/> </ProtectedRoute>}/>
                    <Route path='/profile' element={<ProtectedRoute> <DoctorProfilePage/> </ProtectedRoute>}/>
                    <Route path='/change-password' element={<ProtectedRoute> <ChangePasswordPage/> </ProtectedRoute>}/>
                </Routes>
                <Toaster position="top-right"/>
            </Layout>
        </Router>
    );
}

export default App;
