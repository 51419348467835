import React, {useEffect, useState} from "react";
import {Doughnut} from "react-chartjs-2";
import {allBiomes, BiomeData} from "./ReferenceBiomeData";

function SkinReportPieChart({chartData, isDefault}) {
    const [data, setData] = useState();
    const options = {
        "responsive": true,
        "plugins": {
            "legend": {
                "display": true,
                "position": "right",
                "align": "center",
                labels: {
                    boxWidth: 20,
                    font: {
                        size: 16
                    }

                }
            }
        }
    }

    const label = {
        id: 'label',
        afterDatasetsDraw(chart, args, options, cancelable) {

            const {ctx, data} = chart;
            if (chart.getDatasetMeta(0).data.length === 0) return;
            const centerX = chart.getDatasetMeta(0).data[0].x;
            const centerY = chart.getDatasetMeta(0).data[0].y;

            ctx.save();
            ctx.font = 'bold 24px Arial';
            ctx.fillStyle = 'white';
            ctx.textAlign = 'center';
            // ctx.textBaseline = 'middle';
            // make words 2 line

            if (isDefault) {
                ctx.fillText('Reference', centerX, centerY - 20);
                ctx.fillText('MicroBiome', centerX, centerY + 20);
            } else {
                ctx.fillText('Your', centerX, centerY - 20);
                ctx.fillText('MicroBiome', centerX, centerY + 20);
            }
        }
    }

    function loadPieChart() {
        if (chartData) {
            const chartValues = [];
            const labels = [];
            const values = [];
            const colors = [];
            let otherValue = 0;

            Object.keys(chartData).forEach(key => {
                const biome = BiomeData.find(b => b.key === key);
                if (biome && biome.key !== "other") {
                    chartValues.push({...biome, chartData: chartData[key]});
                    labels.push(biome.name);
                    values.push(chartData[key]);
                    colors.push(biome.color);
                } else {
                    if (allBiomes.includes(key)) {
                        otherValue += Number(chartData[key]);
                    }
                }
            });

            if (otherValue > 0) {
                labels.push("Other");
                values.push(otherValue);
                colors.push(BiomeData.find(b => b.key === "other").color);
                chartValues.push({
                    key: "other",
                    defaultValue: 0.05,
                    color: "#fde3df",
                    name: "Other",
                    chartData: otherValue
                });
            }
            console.log('chartValues', chartValues);
            setData({
                labels: labels,
                datasets: [{
                    data: values,
                    backgroundColor: colors
                }]
            });
            //
            // setOptions({
            //     responsive: true,
            //     maintainAspectRatio: false
            // });
        }
    }

    function loadDefaultPieChart() {
        const labels = BiomeData.map(b => b.name);
        const values = BiomeData.map(b => b.defaultValue);
        const colors = BiomeData.map(b => b.color);

        setData({
            labels: labels,
            datasets: [{
                data: values,
                backgroundColor: colors
            }]
        });
        console.log('data2', data);

        // setOptions({
        //     responsive: true,
        //     maintainAspectRatio: false
        // });
    }

    useEffect(() => {
        if (isDefault) {
            loadDefaultPieChart();
        } else {
            loadPieChart();
        }
    }, [chartData, isDefault]);

    return (
        <div style={{maxWidth: '460px', minWidth: '200px', width: '100%', height: '100%'}}>
            {
                data &&
                <Doughnut
                    data={data}
                    options={options}
                    plugins={[label]}
                />
            }
        </div>
    );
}

export default SkinReportPieChart;