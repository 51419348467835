import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
    baseURL: API_URL,
});

// Adding a request interceptor
axiosInstance.interceptors.request.use(function (config) {
    // Set the `Access-Control-Allow-Origin` header for every request
    config.headers['Access-Control-Allow-Origin'] = '*';
    config.headers['Access-Control-Allow-Methods'] = 'GET, POST, PUT, DELETE, OPTIONS';
    if (!config.headers['Content-Type']) {
        config.headers['Content-Type'] = 'application/json';
    }

    const token = localStorage.getItem('token');
    config.headers['Authorization'] = `Bearer ${token}`

    return config;
}, function (error) {
    return Promise.reject(error);
});

export default axiosInstance;
