import React, {useEffect, useState} from 'react';
import {DivRow} from "../../../../widgets/GeneralBody";
import styled from "styled-components";

// Components


const SkinProfileWidget = ({title, value}) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        if (title === 'Skin Type') {
            setOptions(['normal', 'dry', 'oily', 'combination']);
        } else {
            setOptions(['no', 'mild', 'moderate', 'severe']);
        }
    }, [value, title]);

    //make html for options but they are static so no need to make them dynamic and highlight the selected value make them not option but div with side by side square and make text insie square
    return (
        <MyDiv>
            <h1>{title}</h1>
            <DivRow marginTop={"0"} justifyContent={"end"} gap={"0"}>
                {options.map((option, index) => (
                    <CellStyle key={index} backgroundColor={
                        value === option ? '#6200ff' : index % 2 === 0 ? '#f2f2f2' : '#e0e0e0'
                    }
                               color={value === option ? 'white' : 'black'}>{option}</CellStyle>
                ))}
            </DivRow>
        </MyDiv>
    );
}

export default SkinProfileWidget;

const CellStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    padding: 12px;
    //border-radius: 2px;
    background: ${props => props.backgroundColor};
    color: ${props => props.color};
`;

const MyDiv = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    max-width: 600px;
    background: white;
    padding: 0.6rem;
    border-radius: 0.5rem;

    h1 {
        margin-left: 1rem;
        font-size: 1.5rem;
        color: black;
    }
`;