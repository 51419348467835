import React, { useState, useEffect } from "react";
import { DashboardWrapper } from "../../../components/DashboardWrapper/DashboardWrapper.styles";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../../utils/api";
import { Header } from "../styles";
import { GeneralBody } from "../../../widgets/GeneralBody";
import { ClipLoader } from "react-spinners";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import SkinProfileTab from "../../standalone/SkinProfileTab";
import PersonalGrowTab from "../../standalone/PersonalGrow";
import FaqTab from "../../standalone/FaqTab";

ChartJS.register(ArcElement, Tooltip, Legend);

const PatientSkinReportDetailV2 = () => {
  const { reportId } = useParams();
  const [report, setReport] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [isClipboardCopied, setIsClipboardCopied] = useState(false);

  function selectTab(id) {
    setCurrentTab(id);
  }

  const getTabContent = (tab, report) => {
    switch (tab) {
      default:
      case 0:
        return <SkinProfileTab report={report} />;
      case 1:
        return <PersonalGrowTab report={report} />;

      case 2:
        return <FaqTab report={report} />;
    }
  };

  function shareReport() {
    const url = `${window.location.origin}/share/${reportId}`;
    navigator.clipboard.writeText(url).then(() => {
        setIsClipboardCopied(true);
    }).catch(err => {
        console.error('Failed to copy URL: ', err);
    });
}

  useEffect(() => {
    api.get(`/report_by_id/${reportId}`).then((data) => {
      console.log("data", data);
      setReport(data.data["report"]);
      console.log("report", report);
    });
  }, [reportId]);

  return (
    <DashboardWrapper>
      <Header>
        <h3>Skin Report [{reportId}]</h3>
        <div className="flex flex-row gap-4">
                    <button className="btn" onClick={shareReport}>Share</button>
                </div>
      </Header>
      {report && report["data"] ? (
        <GeneralBody id="myPage">
          <TransitionGroup className="content-wrapper">
            <CSSTransition key={currentTab} classNames="content" timeout={500}>
              {getTabContent(currentTab, report)}
            </CSSTransition>
          </TransitionGroup>
          <div className="flex flex-row gap-3 p-3 tab-buttons">
            <button
              className={currentTab === 0 ? "selected" : ""}
              onClick={() => selectTab(0)}
            >
              Skin Profile
            </button>
            <button
              className={currentTab === 1 ? "selected" : ""}
              onClick={() => selectTab(1)}
            >
              Personal Grow
            </button>
            <button
              className={currentTab === 2 ? "selected" : ""}
              onClick={() => selectTab(2)}
            >
              Faq
            </button>
          </div>
        </GeneralBody>
      ) : (
        <ClipLoader
          color={"#ffffff"}
          loading={true}
          // cssOverride={override}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      )}
    </DashboardWrapper>
  );
};

export default PatientSkinReportDetailV2;
