import styled from "styled-components";

export const CircleAction = styled.div`
    border-radius: 24px;
    font-size: 16px;
    padding: 0 8px;
    background-color: #6100ff;
    min-width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
`

