import {useNavigate, useParams} from "react-router-dom";
import {Header} from "../PatientCabinet/styles";
import React, {useEffect, useState} from "react";
import {GeneralBody} from "../../widgets/GeneralBody";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import styled from "styled-components";
import {ClipLoader} from "react-spinners";
import "../PatientCabinet/skin-reports/skin-report.css";
import {useTranslation} from "react-i18next";
import api from "../../utils/api";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";
import "./detail.css";
import SkinProfileTab from "./SkinProfileTab";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import PersonalGrowTab from "./PersonalGrow";
import FaqTab from "./FaqTab";

ChartJS.register(ArcElement, Tooltip, Legend);

const PatientSkinReportDetailSharePageV2 = () => {

    const {reportId} = useParams();
    const [report, setReport] = useState(null);
    const [isProductDetailOpen, setIsProductDetailOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const navigate = useNavigate();

    const [products, setProducts] = useState([]);

    const [currentTab, setCurrentTab] = useState(0);


    const {t} = useTranslation('common');

    function openProductDetail(product) {
        setSelectedProduct(product);
        setIsProductDetailOpen(true);
    }

    function saveAsPdf() {

        html2canvas(document.getElementById('myPage'), {
            quality: 0.95,
            backgroundColor: '#2c1d37',
            foreignObjectRendering: false,
            windowWidth: 1200,
            width: 1200,

            removeContainer: true
        })
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'my-image-name.jpeg';
                const pdf = new jsPDF('p', 'px', [dataUrl.width + 100, dataUrl.height + 100]);
                pdf.setFillColor('#2c1d37');
                const imgProps = pdf.getImageProperties(dataUrl);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                console.log(imgProps.width, imgProps.height);
                pdf.addImage(dataUrl, 'PNG', 50, 50, 1200 * 2, imgProps.height);
                pdf.save("download.pdf");
            });
    }

    function selectTab(id) {
        setCurrentTab(id);
    }

    useEffect(() => {
        api.get(`/products`)
            .then((response) => {
                setProducts(response.data['products']);
            });
        api.get(`/report_by_id/${reportId}`)
            .then((data) => {
                console.log('data', data);
                setReport(data.data['report']);
                console.log('report', report);
            });
    }, [reportId]);


    return (
      <FullScreeen>
        {/*<IconContext.Provider value={{style: {cursor: 'pointer'}}}>*/}
        {/*    <FaArrowLeft onClick={() => navigate(-1)}/>*/}
        {/*</IconContext.Provider>*/}
        <div className="flex flex-col items-center">
          <div className="mb-4">
            <p className="text-2xl font-bold leading-[30px]">
              Precise Skincare Report
            </p>
          </div>›
          <div className="mb-2">
            <p className="text-lg leading-[30px]">
              Exclusively for{" "}
              <em className="font-semibold">{report?.patient_full_name}</em>
            </p>
          </div>
          <p className="text-lg leading-[30px]">
            By <strong className="font-semibold">Glacies Biome</strong>
          </p>
        </div>
        {/*<button onClick={saveAsPdf}>Save as PDF</button>*/}

        {report && report["data"] ? (
          <GeneralBody id="myPage">
            <TransitionGroup className="content-wrapper">
              <CSSTransition
                key={currentTab}
                classNames="content"
                timeout={500}
              >
                {getTabContent(currentTab, report)}
              </CSSTransition>
            </TransitionGroup>
            <div className="flex flex-row gap-3 p-3 tab-buttons">
              <button
                className={currentTab === 0 ? "selected" : ""}
                onClick={() => selectTab(0)}
              >
                Skin Profile
              </button>
              <button
                className={currentTab === 1 ? "selected" : ""}
                onClick={() => selectTab(1)}
              >
                Personal Grow
              </button>
              <button
                className={currentTab === 2 ? "selected" : ""}
                onClick={() => selectTab(2)}
              >
                Faq
              </button>
            </div>
          </GeneralBody>
        ) : (
          <ClipLoader
            color={"#ffffff"}
            loading={true}
            // cssOverride={override}
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        )}
      </FullScreeen>
    );
}

const getTabContent = (tab, report) => {
    switch (tab) {
        default:
        case 0:
            return (
                <SkinProfileTab report={report}/>
            );
        case 1:
            return (
                <PersonalGrowTab report={report}/>
            );

        case 2:
            return (
                <FaqTab report={report}/>
            );
    }
}

const ReportHeader = styled.div`
 width: 100%;
 display:flex;
 flex-direction:column`;

const FullScreeen = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 64px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #2c1d37;
    color: white;
    overflow: auto;

    @media (max-width: 768px) {
        padding: 16px;
        gap: 12px;
    }
`;

const PieStyleDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 24px;
    gap: 24px;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        flex-direction: column;
        margin: 12px;
        gap: 12px;
    }
`;

const skin_types = [
    {
        title: "OSNW",
        elements: [
            "Oily",
            "Sensitive",
            "None-Pigmented",
            "Wrinkle-prone"
        ]
    }
]

export default PatientSkinReportDetailSharePageV2;