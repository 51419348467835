const BiomeData = [
    {
        key: "actinobacteria",
        defaultValue: 0.50,
        color: "#ca498c",
        name: "Actinobacteria"
    },
    {
        key: "firmicutes",
        defaultValue: 0.30,
        color: "#b977ac",
        name: "Firmicutes"
    },
    {
        key: "bacteroidetes",
        defaultValue: 0.10,
        color: "#cf9bbd",
        name: "Bacteroidetes"
    },
    {
        key: "proteobacteria",
        defaultValue: 0.05,
        color: "#e6bece",
        name: "Proteobacteria"
    },
    {
        key: "other",
        defaultValue: 0.05,
        color: "#fde3df",
        name: "Other"
    }
]

const allBiomes = [...BiomeData.map(b => b.key), 'cyanobacteria', 'fusobacteriota', 'patescibacteria', 'desulfobacterota', 'spirochaetota', 'synergistota'];

export {BiomeData, allBiomes};