import React from "react";

const Osnw = () => {


    return (
        <div className="flex flex-col gap-3 pb-3">
            <p>The OSNW skin type, an acronym standing for Oily, Sensitive, Non-pigmented, and Wrinkle-prone, is one of
                the categories within the Baumann Skin Type Indicator, a dermatological tool used to classify various
                skin characteristics. This classification helps in identifying the most effective skincare routines and
                treatments for individuals based on their specific skin attributes.</p>
            <table className="border-collapse oswn-table">
                <tr className="border-b border-b-black">
                    <td>
                        <h2 style={{minWidth: '100px'}}>Oily</h2>
                    </td>
                    <td>
                        <p style={{fontSize: 8}}>
                            People with the OSNW skin type typically have an overproduction of sebum, leading to an oily
                            complexion. This oiliness can contribute to the development of acne and other skin
                            blemishes,
                            making it crucial for individuals with this skin type to maintain a consistent and effective
                            skincare routine that controls oil production without over-drying the skin.
                        </p>
                    </td>
                </tr>
                <tr className="border-b-2">
                    <td>
                        <h2 style={{minWidth: '100px'}}>Sensitive</h2>
                    </td>
                    <td>
                        <p style={{fontSize: 8}}>
                            Sensitivity is another hallmark of the OSNW skin type. Sensitive skin reacts easily to
                            environmental factors, irritants, and certain skincare products, often resulting in redness,
                            itching, or other forms of irritation. Individuals with sensitive skin need to be
                            particularly cautious about the ingredients in their skincare products, opting for
                            hypoallergenic and non-irritating formulations.
                        </p>
                    </td>
                </tr>

                <tr className="border-b-2">
                    <td>
                        <h2 style={{minWidth: '100px'}}>None-Pigmented</h2>
                    </td>
                    <td>
                        <p style={{fontSize: 8}}>
                            Sensitivity is another hallmark of the OSNW skin type. Sensitive skin reacts easily to
                            environmental factors, irritants, and certain skincare products, often resulting in redness,
                            itching, or other forms of irritation. Individuals with sensitive skin need to be
                            particularly cautious about the ingredients in their skincare products, opting for
                            hypoallergenic and non-irritating formulations.
                        </p>
                    </td>
                </tr>

                <tr>
                    <td>
                        <h2 style={{minWidth: '100px'}}>Wrinkle-prone</h2>
                    </td>
                    <td>
                        <p style={{fontSize: 8}}>
                            Lastly, the 'W' for wrinkle-prone indicates that this skin type may be more susceptible to
                            the early development of fine lines and wrinkles. This trait can be exacerbated by the
                            skin's oiliness and sensitivity, as well as environmental factors like sun exposure.
                            Individuals with the OSNW skin type should incorporate anti-aging products and practices
                            into their skincare routine early on to mitigate these effects.
                        </p>
                    </td>
                </tr>
            </table>
            <p>
                In conclusion, managing the ONW skin type requires a balanced approach that addresses each aspect of the
                skin's characteristics. This includes using gentle, non-comedogenic skincare products, avoiding
                irritants, protecting the skin from sun exposure, and incorporating anti-aging treatments to maintain
                the skin's health and appearance.
                Consulting with a dermatologist can provide personalized advice and recommendations to effectively care
                for the OSNW skin type.
            </p>
        </div>
    );
}

export default Osnw;