import React from "react";
import Accordion from "../../widgets/accardion";

const PersonalGrowTab = ({report}) => {


    return (
        <div className="flex flex-wrap gap-3 m-auto vertical-layout" style={{maxWidth: "1300px"}}>
            <div className="flex flex-col gap-3 flex-1">
                <Accordion
                    title={"Why Glacies Skin Analysis?"}
                    children={
                        <p>
                            Have you ever been startled with the choice of staggering skincare products and wondered
                            which one is actually the right choice for your face? There are hundreds of choices for each
                            of skincare product lines.
                            Take wrinkle creams, acne solutions, moisturizers, cleansers, and many more. While these
                            products solve your visual concerns, are they tailored for your long-term skin health? How
                            are they interacting with the essential protection layer on your face, and your skin
                            microbiome?
                        </p>
                    }
                />
            </div>
            <div className="flex flex-col gap-3 flex-1">
                <Accordion
                    title={"How it works?"}
                    children={
                        <p>
                            We merge the latest advancements in sequencing technology and microbiome research with state
                            of the art Al models to uniquely characterize your skin as a whole considering both visual
                            and hidden biology underneath. Our technology creates a customized set of skincare products
                            that fits best to your skin. Glacies knowledge engine combines three sources of information,
                            skin microbiome collected by swab of your skin, meticulously drafted questionnaire to
                            understand your unique habits and facial image to characterize the individual skin profile.
                            Results? Let's explore your skin then!
                        </p>
                    }
                />
            </div>

            <div className="flex flex-col gap-3 flex-1">
                <Accordion
                    title={"Why Skin MicroBiome Matters"}
                    children={
                        <p>
                            Did you know maintaining a healthy skin biota slows skin aging and help to maintain the best
                            look of yourself? At the same time, the primary protective barrier of face against
                            environmental and biological factors are maintained by an intricate relationship between
                            facial microbiome and skin cells.
                        </p>
                    }
                />
            </div>
        </div>
    );
}

export default PersonalGrowTab;