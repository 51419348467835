import styled from "styled-components";
import BgImage from "../../assets/welcome-banner.jpg";

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 200px;
    border-radius: 10px;
    padding: 12px 37px;
    margin: 30px 0 20px;
    background: url(${BgImage}) lightgray 50% / cover no-repeat;

    p {
        font-size: 18px;
        font-weight: 300;
        line-height: normal;
    }

    h3 {
        font-size: 32px;
        font-weight: 400;
        line-height: normal;
    }
`;