import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {DivRow} from "../../../widgets/GeneralBody";

// Components


const SkinProfileWidgetShare = ({title, value}) => {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        if (title === 'Skin Type') {
            setOptions(['normal', 'dry', 'oily', 'combination']);
        } else {
            setOptions(['no', 'mild', 'moderate', 'severe']);
        }
    }, [value, title]);

    //make html for options but they are static so no need to make them dynamic and highlight the selected value make them not option but div with side by side square and make text insie square
    return (
        <DivRow marginTop={"0"} justifyContent={"end"} gap={"0"}>
            {options.map((option, index) => (
                <CellStyle key={index} backgroundColor={
                    value === option ? '#6200ff' : index % 2 === 0 ? '#f2f2f2' : '#e0e0e0'
                }
                           color={value === option ? 'white !important' : 'black'}>{option}</CellStyle>
            ))}
        </DivRow>
    );
}

export default SkinProfileWidgetShare;

const CellStyle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    //height: 20px;
    padding: 8px;
    //border-radius: 2px;
    background: ${props => props.backgroundColor};
    color: ${props => props.color};
`;