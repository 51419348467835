import React from "react";
import Accordion from "../../widgets/accardion";
import SkinReportStraightChart from "../PatientCabinet/skin-reports/SkinReportStarightChart";
import DiversityIndexWidget from "../../widgets/diversity-index-slider";
import BarChartTable from "../../widgets/bar-chart";
import Osnw from "./skin_indexs/osnw";
import SkinProfileWidgetShare from "./widget/skin-profile.widget";
import {CircleAction} from "./widget/circle-action";

const SkinProfileTab = ({report}) => {


    return (
        <div className="flex flex-wrap gap-3 m-auto vertical-layout" style={{maxWidth: "1300px"}}>
            <div className="flex flex-col gap-3 flex-1">
                <Accordion
                    title={"Biological Skin Age"}
                    rightAction={
                        <CircleAction>
                            {report['data']['skin_age']}
                        </CircleAction>
                    }
                    children={<p>Biological skin age expresses the condition of a skin based on various
                        multiple
                        factors, not determined by your chronological age. We assess the health,
                        vitality,
                        and
                        appearance of the skin, influenced by factors such as genetics, lifestyle
                        choices,
                        environmental exposure, and skincare applications. Additionally, we determine
                        your
                        biological skin age by calculating your microbiome diversity, skin hydration,
                        wrinkles,
                        pigmentation, tone, and collagen structure.</p>}
                />
                <Accordion
                    title={"Your Microbiome"}
                    children={
                        <div className="flex flex-col gap-3">
                            <SkinReportStraightChart chartData={report['data']}/>
                            <SkinReportStraightChart isDefault={true}/>
                            <p>
                                Microbiome data is isolated on the same day from the samples taken from
                                you at our laboratory. The obtained microbial DNA is frozen and sent to
                                our
                                partner laboratories in Munich, Germany for next-generation sequencing.
                                After
                                obtaining your personal microbiome sequencing data, the results are
                                delivered to
                                our headquarters in Luxembourg. Here, all your data is analyzed with
                                Glacies
                                technology, and your personalized facial profile is created.
                            </p>
                        </div>

                    }
                />

                <Accordion
                    title={"Microbiome Diversity Index"}
                    rightAction={
                        <CircleAction>
                            {report['data']['diversity_index']}
                        </CircleAction>
                    }
                    children={
                        <div className="flex flex-col gap-3">
                            <p>
                                The presence and proportions of different bacteria are among the most
                                important indicators of skin health. An excessive abundance of any
                                bacteria is considered an early sign of disease. We calculate your
                                biodiversity index by measuring the amount of bacterial genetic material
                                present in the samples taken from your face.
                            </p>
                            {
                                report['data']['diversity_index'] &&
                                <DiversityIndexWidget
                                    num={Number(report['data']['diversity_index'])}></DiversityIndexWidget>
                            }
                            <p>
                                Microbial diversity on your face is at optimal range. Your personalized
                                skincare routine will help you to maintain the microbiome diversity on
                                your skin.
                            </p>
                            {
                                report['data'] &&
                                <BarChartTable myGraphData={report['data']}></BarChartTable>
                            }
                        </div>
                    }
                />
            </div>
            <div className="flex flex-col gap-3 flex-1">
                <Accordion
                    title={"Your Skin Index"}
                    rightAction={
                        <h1 className="font-bold">{(report['data']['skin_index'] || '').toUpperCase()}</h1>
                    }
                    children={
                        <Osnw/>
                    }
                >

                </Accordion>
                <Accordion
                    title={"Acne"}
                    rightAction={
                        <SkinProfileWidgetShare value={report['data']['acne']}/>
                    }
                    children={
                        <p>
                            Acne on the face is a common skin condition characterized by the appearance
                            of pimples, blackheads, and cysts, primarily caused by the blockage and
                            inflammation of hair follicles and sebaceous glands, often exacerbated by
                            hormonal changes and bacterial overgrowth.
                            <br/>
                            <br/>
                            Based on your results, you have
                            a <strong>{report['data']['acne']}</strong> acne.
                        </p>
                    }
                >
                </Accordion>
                <Accordion
                    title={"Pigmentation"}
                    rightAction={
                        <SkinProfileWidgetShare value={report['data']['pigmentation']}/>
                    }
                    children={
                        <p>
                            Pigmentation or hyperpigmentation on the face refers to the darkening of
                            certain areas of skin caused by an excess production of melanin, often
                            triggered by factors like sun exposure, hormonal changes, inflammation, or
                            certain skin injuries or conditions
                            <br/>
                            <br/>
                            Based on your results, you have
                            a <strong>{report['data']['pigmentation']}</strong> concerns.
                        </p>
                    }
                >
                </Accordion>
            </div>
            <div className="flex flex-col gap-3 flex-1">
                <Accordion
                    title={"Skin Type"}
                    rightAction={
                        <SkinProfileWidgetShare title={"Skin Type"} value={report['data']['skin_type']}/>
                    }
                    children={
                        <p>
                            A facial skin type is a classification based on the skin's characteristics
                            and behavior. Common skin types include normal, oily, dry, and combination,
                            each with distinct needs and responses to environmental factors, skincare
                            products, and routines.
                            <br/>
                            <br/>
                            Based on your results, you have
                            a <strong>{report['data']['wrinkles']}</strong> skin.
                        </p>
                    }
                >
                </Accordion>
                <Accordion
                    title={"Wrinkles"}
                    rightAction={
                        <SkinProfileWidgetShare value={report['data']['wrinkles']}/>
                    }
                    children={
                        <p>
                            A facial skin type is a classification based on the skin's characteristics
                            and behavior. Common skin types include normal, oily, dry, and combination,
                            each with distinct needs and responses to environmental factors, skincare
                            products, and routines.
                            <br/>
                            <br/>
                            Based on your results, you have
                            a <strong>{report['data']['wrinkles']}</strong> skin.
                        </p>
                    }
                >
                </Accordion>
            </div>
        </div>
    );
}

export default SkinProfileTab;