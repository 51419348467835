import styled from "styled-components";

export const AccardionStyle = styled.div`
    border: 1px solid black;
    overflow: hidden;
    background: white;
    border-radius: 18px;
    color: black;

    * {
        color: black;
    }

    .question-container {
        width: 100%;
        text-align: left;
        padding: 12px 12px;
        display: flex;
        height: 49px;
        align-items: center;
        justify-content: space-between;
        font-weight: 500;
        font-size: 16px;
        background: transparent;
        border: none;
        cursor: pointer;
        color: black;
    }

    .question-content {
        color: black;
    }

    .question-container.active {
        color: #1db954;
        background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.04), transparent);
    }

    .arrow {
        font-size: 2rem;
        transition: .5s ease-in-out;
        color: black;
    }

    .arrow.active {
        rotate: 180deg;
        color: #1db954;
    }

    .answer-container {
        padding: 0 1rem;
        transition: height .7s ease-in-out;
    }

    .answer-content {
        p {
            text-align: justify;
            font-size: 14px;
            letter-spacing: 0.5px;
            font-weight: lighter;
        }

        color: black;
    }
`;