import styled, {keyframes} from "styled-components";


const slideAnim = (top, left) => keyframes`
    100% {
        top: ${top};
        left: ${left};
    }
`;

export const DashboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 45px;
    @media (max-width: 768px) {
        margin: 20px 5px;
    }
    padding: 20px 32px 30px 32px;
    border-radius: 20px;
    background: rgba(13, 9, 29, 0.40);
    height: 100%;
    overflow-y: auto;
`;

export const Wrapper = styled.div`
    display: flex;
    height: calc(100vh - 30px);
`;
