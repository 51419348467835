import React, {useCallback, useContext, useEffect, useState} from 'react';
import {DashboardWrapper} from "../../../components/DashboardWrapper/DashboardWrapper.styles";
import {IconContext} from "react-icons";
import {FaArrowLeft} from "react-icons/fa";
import {Header, StyledModal} from "../styles";
import {useNavigate, useParams} from "react-router-dom";
import {GeneralBody} from "../../../widgets/GeneralBody";
import api from "../../../utils/api";
import {Button, Popup} from "../../../components/PatientProfile/PatientProfile.styles";
import CloseModal from "../../../components/CloseModal";
import {ListItemDiv} from "../../../widgets/ListItem/ListItem.style";
import {format} from "date-fns";
import {SkinReportsContext} from "./SkinReportsContext";
import {UserContext} from "../../../context/UserContext";
import {useTranslation} from "react-i18next";

const PatientSkinReportPage = () => {
    const navigate = useNavigate();
    const skinReportContext = useContext(SkinReportsContext);
    const userContext = useContext(UserContext);

    const {patientId} = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [reports, setReports] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isRequestCreated, setIsRequestCreated] = useState(false);
    const [requestMessage, setRequestMessage] = useState('');
    const {t} = useTranslation('common');

    const fetchReports = useCallback(async () => {


        try {
            setLoading(true);
            const response = await api.get(`/report/${patientId}`);

            if (response.status !== 200) {
                throw new Error('Failed to fetch reports');
            }

            const data = await response.data;
            setReports(data['reports']);
            skinReportContext.setReports(data['reports']);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error.message);
        }
    }, [patientId]);

    const openSkinReport = (reportId) => {
        navigate(`${reportId}`);
    }

    const createReport = async () => {
        try {
            console.log('patientId', patientId);
            console.log('userContext', userContext.user);
            const response = await api.post(`/report_requests`,
                {
                    requested_for: patientId,
                    requested_by: userContext.user.id,
                }
            );

            setIsRequestCreated(true);
            if (response.status !== 201) {

                setIsRequestCreated(true);
                setRequestMessage('Failed to create report request');
                throw new Error('Failed to create report request');
            }
            setRequestMessage('Report request created successfully');
        } catch (error) {

            console.log('userContext', userContext.user);

            setIsRequestCreated(true);
            setRequestMessage('Failed to create report request');
        }
    }

    useEffect(() => {
        if (patientId) {
            fetchReports();
        }
    }, [patientId, fetchReports]);

    return (
        <DashboardWrapper>
            <Header>
                <IconContext.Provider value={{style: {cursor: 'pointer'}}}>
                    <FaArrowLeft onClick={() => navigate(-1)}/>
                </IconContext.Provider>
                <h3>Glacies Skin Reports</h3>
                <Button
                    className="max-w-44"
                    onClick={() => {
                        createReport();
                    }}>{t('request_new_report')}</Button>
            </Header>
            <GeneralBody>
                {
                    reports.map((report, index) => {
                        return (
                            <ListItemDiv
                                key={index}
                                onClick={() => openSkinReport(report['id'])}
                            >
                                <p>Report {index}</p>
                                <h4>{report['id']}</h4>
                                <p>{format(report['created_at'], 'MMMM dd, yyyy H:mma')}</p>
                            </ListItemDiv>
                        );
                    })
                }
            </GeneralBody>
            <StyledModal
                isOpen={isRequestCreated}
                onBackgroundClick={() => setIsRequestCreated(false)}
                onEscapeKeydown={() => setIsRequestCreated(false)}>
                <Popup>
                    <h3>Info!</h3>
                    <p>{requestMessage}</p>
                </Popup>
                <CloseModal onClick={() => {
                    setIsRequestCreated(false);
                }}/>
            </StyledModal>
        </DashboardWrapper>
    );

}

export default PatientSkinReportPage;
