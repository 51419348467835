import React, { useEffect, useState } from "react";
import { allBiomes, BiomeData } from "./ReferenceBiomeData";

function SkinReportStraightChart({ chartData, isDefault }) {
  const [data, setData] = useState();
  const [dataSum, setDataSum] = useState(0)

  function loadChart() {
    if (chartData) {
      const chartValues = [];
      const labels = [];
      const values = [];
      const colors = [];
      let otherValue = 0;
      let sum = 0;

      Object.keys(chartData).forEach((key) => {
        const biome = BiomeData.find((b) => b.key === key);
        if (biome && biome.key !== "other") {
          chartValues.push({ ...biome, chartData: chartData[key] });
          sum+=chartData[key];
        } else {
          if (allBiomes.includes(key)) {
            otherValue += Number(chartData[key]);
          }
        }
      });

      if (otherValue > 0) {
        sum+=otherValue;
        chartValues.push({
          key: "other",
          defaultValue: 0.05,
          color: "#fde3df",
          name: "Other",
          chartData: otherValue,
        });
      }
      setDataSum(sum);
      console.log("chartValues", chartValues);
      setData(chartValues);
    }
  }

  function loadDefaultPieChart() {
    const labels = BiomeData.map((b) => b.name);
    const values = BiomeData.map((b) => b.defaultValue);
    const colors = BiomeData.map((b) => b.color);
    const chartValues = BiomeData.map((b) => ({
      ...b,
      chartData: b.defaultValue,
    }));

     const sum = values.reduce((sum, value) => sum + value, 0);
     setDataSum(sum);

    setData(chartValues);
  }

  useEffect(() => {
    if (isDefault) {
      loadDefaultPieChart();
    } else {
      loadChart();
    }
  }, [chartData, isDefault]);

  return (
    <div>
      {data ? (
        <div className="flex flex-col">
          <div className="flex flex-row gap-0">
            {data &&
              data.map((item, index) => (
                <div
                  style={{
                    height: "36px",
                    flex: item.chartData,
                    backgroundColor: item.color,
                  }}
                ></div>
              ))}
          </div>
          <div className="flex flex-wrap flex-row gap-2 p-1 justify-end">
            {data &&
              data.map((item, index) => (
                <div class="flex gap-1 p-1 border rounded-lg">
                  <div
                    style={{
                      backgroundColor: item.color,
                      height: "12px",
                      width: "12px",
                    }}
                  ></div>
                 <p className="text-xs leading-3"> {(item.chartData/dataSum*100).toFixed(2)}%</p>
                </div>
              ))}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <span>No data</span>
        </div>
      )}
    </div>
  );
}

export default SkinReportStraightChart;
