import styled from "styled-components";

export const FeaturesWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 35px;
    flex-wrap: wrap;
    margin-top: 50px;
    flex-direction: row-reverse;
`;
