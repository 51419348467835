import {useNavigate, useParams} from "react-router-dom";
import {Header, StyledModal} from "../PatientCabinet/styles";
import React, {useEffect, useState} from "react";
import {DivRow, GeneralBody} from "../../widgets/GeneralBody";
import SkinReportPieChart from "../PatientCabinet/skin-reports/SkinReportPieChart";
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import styled from "styled-components";
import {Circle} from "../../widgets/AddIcon/AddIcon.style";
import {ClipLoader} from "react-spinners";
import "../PatientCabinet/skin-reports/skin-report.css";
import DiversityIndexWidget from "../../widgets/diversity-index-slider";
import BarChartTable from "../../widgets/bar-chart";
import SkinProfileWidget from "../PatientCabinet/skin-reports/widget/skin-profile.widget";
import {useTranslation} from "react-i18next";
import api from "../../utils/api";
import {Popup} from "../../components/PatientProfile/PatientProfile.styles";
import CloseModal from "../../components/CloseModal";
import {jsPDF} from "jspdf";
import html2canvas from "html2canvas";

ChartJS.register(ArcElement, Tooltip, Legend);

const PatientSkinReportDetailSharePage = () => {

    const {reportId} = useParams();
    const [report, setReport] = useState(null);
    const [isProductDetailOpen, setIsProductDetailOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const navigate = useNavigate();

    const [products, setProducts] = useState([]);


    const {t} = useTranslation('common');

    function openProductDetail(product) {
        setSelectedProduct(product);
        setIsProductDetailOpen(true);
    }

    function saveAsPdf() {

        html2canvas(document.getElementById('myPage'), {
            quality: 0.95,
            backgroundColor: '#2c1d37',
            foreignObjectRendering: false,
            windowWidth: 1200,
            width: 1200,

            removeContainer: true
        })
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'my-image-name.jpeg';
                const pdf = new jsPDF('p', 'px', [dataUrl.width + 100, dataUrl.height + 100]);
                pdf.setFillColor('#2c1d37');
                const imgProps = pdf.getImageProperties(dataUrl);
                const pdfWidth = pdf.internal.pageSize.getWidth();
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                console.log(imgProps.width, imgProps.height);
                pdf.addImage(dataUrl, 'PNG', 50, 50, 1200 * 2, imgProps.height);
                pdf.save("download.pdf");
            });
    }

    useEffect(() => {
        api.get(`/products`)
            .then((response) => {
                setProducts(response.data['products']);
            });
        api.get(`/report_by_id/${reportId}`)
            .then((data) => {
                console.log('data', data);
                setReport(data.data['report']);
                console.log('report', report);
            });
    }, [reportId]);

    return (
        <FullScreeen>
            <Header>
                {/*<IconContext.Provider value={{style: {cursor: 'pointer'}}}>*/}
                {/*    <FaArrowLeft onClick={() => navigate(-1)}/>*/}
                {/*</IconContext.Provider>*/}
                <h3>Glacies Skin Report [{reportId}]</h3>
                {/*<button onClick={saveAsPdf}>Save as PDF</button>*/}
            </Header>
            {
                report && report['data'] ?
                    <GeneralBody id="myPage">
                        <DivRow style={{
                            margin: "24px",
                            marginBottom: "24px"
                        }}>
                            <h1>Your Microbiome</h1>
                            <DivRow maxWidth={320}>
                                <h1>Skin Age</h1>
                                <Circle>{report['data']['skin_age']}</Circle>
                            </DivRow>
                        </DivRow>
                        <PieStyleDiv>
                            <SkinReportPieChart chartData={report['data']}/>
                            <SkinReportPieChart isDefault={true}/>
                        </PieStyleDiv>
                        <DivRow>
                            <div className={"diversity-index"}>
                                <h1>Diversity Index</h1>
                                {
                                    report['data']['diversity_index'] &&
                                    <DiversityIndexWidget
                                        num={Number(report['data']['diversity_index'])}></DiversityIndexWidget>
                                }
                            </div>
                            <div>
                                {
                                    report['data'] &&
                                    <BarChartTable myGraphData={report['data']}></BarChartTable>
                                }
                            </div>

                        </DivRow>
                        <h1 style={{marginTop: '64px'}}>Skin Profile</h1>
                        <DivRow>
                            <SkinProfileWidget title={'Acne'} value={report['data']['acne']}></SkinProfileWidget>
                            <SkinProfileWidget title={'Pigmentation'}
                                               value={report['data']['pigmentation']}></SkinProfileWidget>
                            <SkinProfileWidget title={'Wrinkles'}
                                               value={report['data']['wrinkles']}></SkinProfileWidget>
                            <SkinProfileWidget title={'Skin Type'}
                                               value={report['data']['skin_type']}></SkinProfileWidget>
                        </DivRow>
                        <div className="flex flex-row flex-wrap gap-12 mt-32 details">
                            <div className="flex flex-col flex-1 gap-12">
                                <DivRow>
                                    <h1>Skin Index</h1>
                                    <div style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        gap: '1rem',
                                        backgroundColor: 'white',
                                        padding: '1rem',
                                        borderRadius: '8px',
                                    }}>
                                        <h4 style={{
                                            color: 'black',
                                        }}>{(report['data']['skin_index'] || '').toUpperCase()}</h4>

                                    </div>
                                </DivRow>
                                <p style={{whiteSpace: "pre-line"}}>{t(report['data']['skin_index'] + '_description')}</p>
                            </div>
                            <div className="flex flex-col justify-between flex-1 gap-4">
                                {
                                    skin_types.length > 0 &&
                                    skin_types.find((skin_type) => skin_type.title === report['data']['skin_index'])
                                        .elements.map((element, index) => (
                                        <div key={index} className="flex flex-row gap-4">
                                            <div className="flex flex-row gap-4">
                                                <h3 className="min-w-40">{element}</h3>
                                                <p>{t(element)}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>

                        <h1 className="mt-32">Routine</h1>
                        <div>
                            {
                                report['data']['products'] &&
                                products.length > 0 &&
                                report['data']['products'].map((product, index) => {
                                    const productData = products.find((p) => p.id === product);
                                    return (
                                        <div key={index} className="bg-gray-700 mt-6 rounded-lg"
                                             onClick={() => openProductDetail(productData)}>
                                            <div
                                                className="flex flex-row justify-between p-3 cursor-pointer items-center">
                                                <img src={productData.image} className="productImg" alt="" height="30px"
                                                     width="auto"/>
                                                <p className="flex-1 ml-3">{productData.name}</p>
                                                <p>{productData.application_time}</p>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>


                    </GeneralBody>
                    :
                    <ClipLoader
                        color={"#ffffff"}
                        loading={true}
                        // cssOverride={override}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
            }
            <StyledModal
                isOpen={isProductDetailOpen}
                onBackgroundClick={() => setIsProductDetailOpen(false)}
                onEscapeKeydown={() => setIsProductDetailOpen(false)}>
                <Popup className="overflow-auto">
                    {
                        selectedProduct &&
                        <h1 className="mb-12">Name: {selectedProduct.name}</h1>
                    }
                    {
                        selectedProduct &&
                        <div>
                            <img className="rounded-2xl mb-12" src={selectedProduct.image} alt={selectedProduct.name}
                                 height={100}/>

                            <p>Usage Instructions: {selectedProduct.usage_instructions}</p>
                            <p>Ingredients: {selectedProduct.ingredients.join(', ')}</p>
                            <p>Description: {selectedProduct.description}</p>
                        </div>
                    }
                </Popup>
                <CloseModal onClick={() => {
                    setIsProductDetailOpen(false);
                }}/>
            </StyledModal>
        </FullScreeen>
    );
}
const FullScreeen = styled.div`
    width: 100%;
    height: 100%;
    padding: 64px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #2c1d37;
    color: white;
    overflow: auto;

    @media (max-width: 768px) {
        padding: 16px;
        gap: 12px;
    }
`;

const PieStyleDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin: 24px;
    gap: 24px;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        flex-direction: column;
        margin: 12px;
        gap: 12px;
    }
`;

const skin_types = [
    {
        title: "OSNW",
        elements: [
            "Oily",
            "Sensitive",
            "None-Pigmented",
            "Wrinkle-prone"
        ]
    }
]

export default PatientSkinReportDetailSharePage;