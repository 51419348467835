import React, {useEffect, useState} from 'react';

// Components
import {DashboardWrapper} from '../../../components/DashboardWrapper/DashboardWrapper.styles';
import {FaArrowLeft, FaRedo, FaRegFile, FaVideo} from "react-icons/fa";
import {AiOutlineCloudUpload} from "react-icons/ai";
import {MdAddAPhoto} from "react-icons/md";
import {useNavigate, useParams} from "react-router-dom";
import {IconContext} from "react-icons";
import {Header} from "../styles";
import {GeneralBody} from "../../../widgets/GeneralBody";
import {uploadPatientImage} from "../../../utils/api-lists";
import toast from "react-hot-toast";
import Camera from "../../../components/Camera";
import Modal from "styled-react-modal";
import CloseModal from "../../../components/CloseModal";
import {useTranslation} from "react-i18next";
import {getPatient} from '../../../utils/api-lists/patient.api';

const StyledModal = Modal.styled`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FileUploadPage = () => {
    const navigate = useNavigate();
    const {patientId} = useParams();

    const {t} = useTranslation('common');

    const [file, setFile] = useState();
    const [fileURL, setFileURL] = useState();
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [isVideo, setIsVideo] = useState(false);
    const [patient, setPatient] = useState()

    useEffect(() => {
        getPatient(patientId)
            .then((data) => {
                setPatient(data);
            })
            .catch((error) => console.log(error.message));
        document.title = "Visual analysis: File upload";
    }, [patientId]);

    function toggleModal(isVideo = false) {
        setIsOpen(!isOpen);
        setIsVideo(isVideo);
    }

    function handleChange(event) {
        setUploadProgress(0);
        const selectedFile = event.target.files[0];
        setFile(selectedFile);
        const url = URL.createObjectURL(selectedFile);
        setFileURL(url);
    }

    function base64ToFile(base64, filename) {
        const arr = base64.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type: mime});
    }

    function generateRandomNumber() {
        return Math.floor(Math.random() * 1000000);
    }

    function saveImage(image) {
        const tempfile = base64ToFile(image, `webcam-${generateRandomNumber()}.jpg`);
        setFile(tempfile);
        const url = URL.createObjectURL(tempfile);
        setFileURL(url);
        console.log('file', url);
        toggleModal();
    }

    function saveVideo(video) {
        const newVideo = new File([video], `webcam-${generateRandomNumber()}.webm`, {type: 'video/webm'});
        setFile(newVideo);
        console.log('video', newVideo);
        const url = URL.createObjectURL(newVideo);
        setFileURL(url);
        toggleModal();
    }

    function handleSubmit(event) {
        event.preventDefault();

        const date = new Date().toISOString();
        const fileExt = file.name.substring(file.name.lastIndexOf(".") + 1);
        const fileName = `${patient.first_name}_${patient.last_name}_${date}.${fileExt}`

        const config = {
            onUploadProgress: function (progressEvent) {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            }
        };
        toast.promise(
            uploadPatientImage(patientId, file, fileName, config), {
                loading: 'Uploading...',
                success: 'Upload successful!',
                error: (e) => `Upload failed ${e.response.data.message}`
            })
            .then(() => {
                navigate(-1);
            })
            .catch((e) => {

            });
    }

    return (
        <DashboardWrapper>
            <Header>
                <IconContext.Provider value={{style: {cursor: "pointer"}}}>
                    <FaArrowLeft onClick={() => navigate(-1)}/>
                </IconContext.Provider>
                <h3>Visual Analysis: Upload file</h3>
            </Header>

            <GeneralBody>
                <div
                    className="flex flex-wrap lg:flex-nowrap w-full max-w-6xl bg-opacity-5 rounded-xl shadow-lg overflow-hidden">
                    {/* Left Side (Buttons) */}
                    <div className="fileSelection"></div>
                    <div
                        className="w-full lg:w-1/4 p-6 flex flex-col justify-center items-center gap-6 bg-[rgba(88,80,109,0.30)] rounded-t-xl">
                        {!file && (
                            <>
                                <label
                                    className="min-w-[150px] max-w-[180px] flex items-center justify-center gap-2 w-full py-3 text-sm font-bold bg-blue-500 text-white rounded-lg cursor-pointer text-center transition duration-300 transform hover:bg-blue-600">
                                    <FaRegFile className="text-2xl"/>
                                    {t("choose_a_file")}
                                    <input
                                        name="file"
                                        id="file"
                                        type="file"
                                        onChange={handleChange}
                                        className="hidden"
                                    />
                                </label>

                                <button
                                    className="min-w-[150px] max-w-[180px] flex items-center justify-center gap-2 w-full py-3 text-sm font-bold bg-blue-500 text-white rounded-lg transition duration-300 transform hover:bg-blue-600"
                                    onClick={() => toggleModal()}
                                >
                                    <MdAddAPhoto className="text-2xl"/>
                                    {t("capture_photo")}
                                </button>

                                <button
                                    className="min-w-[150px] max-w-[180px] flex justify-center items-center gap-2 w-full py-3 text-sm font-bold bg-blue-500 text-white rounded-lg transition duration-300 transform hover:bg-blue-600"
                                    onClick={() => toggleModal(true)}
                                >
                                    <FaVideo className="text-2xl"/> {t("record_video")}
                                </button>
                            </>
                        )}

                        {file && (
                            <>
                                <button
                                    onClick={handleSubmit}
                                    className="min-w-[150px] max-w-[180px] flex justify-center items-center gap-2 w-full py-3 text-sm font-bold bg-purple-600 text-white rounded-lg transition duration-300 transform hover:bg-purple-700 mb-10"
                                >
                                    <AiOutlineCloudUpload className="text-2xl"/>
                                    {t("upload")}
                                </button>
                                <button
                                    className="min-w-[150px] max-w-[180px] flex justify-center items-center gap-2 w-full py-3 text-sm font-bold bg-blue-500 text-white rounded-lg transition duration-300 transform hover:bg-blue-600"
                                    onClick={() => setFile()}
                                >
                                    <FaRedo className="text-2xl"/> {t("re_select")}
                                </button>
                                {uploadProgress > 0 && (
                                    <progress value={uploadProgress} max="100"></progress>
                                )}
                            </>
                        )}
                    </div>

                    {/* Right Side (Preview) */}
                    <div
                        className="w-full lg:w-3/4 h-[400px] p-6 flex justify-center items-center bg-[#121530] rounded-r-xl">
                        <div
                            id="preview"
                            className="flex justify-center items-center w-full h-full"
                        >
                            {file && file.type.startsWith("image") && fileURL &&
                                <img className="max-w-full max-h-full object-fit" src={fileURL}/>}
                            {file && file.type.startsWith("video") && fileURL &&
                                <video controls className="max-w-full max-h-full object-fit" src={fileURL}/>}
                            {!file && (
                                <p className="text-lg font-semibold text-center">
                                    No content captured yet.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </GeneralBody>

            <StyledModal
                isOpen={isOpen}
                onBackgroundClick={toggleModal}
                onEscapeKeydown={toggleModal}
            >
                <Camera
                    saveImage={saveImage}
                    isVideo={isVideo}
                    saveVideo={saveVideo}
                />
                <CloseModal onClick={toggleModal}/>
            </StyledModal>
        </DashboardWrapper>
    );

}

export default FileUploadPage;
