import React, { useEffect, useRef, useState } from "react";
import { barColors, listFields } from "./fields";
import api from "../../utils/api";

const BarChartComponent = ({ country = "Austria", myGraphData }) => {
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const [canvasWidth, setCanvasWidth] = useState(805); // Default width
  const canvasHeight = 420; // Fixed aspect ratio

  const barNumber = 31;
  const colors = barColors;
  const fields = listFields;
  let totalSum = 0;
  let totalNumber = 0;
  let ctx = null;

  const loadUserGraph = () => {
    const canvas = canvasRef.current;
    ctx = canvas.getContext("2d");

    if (myGraphData && ctx) {
      const xSections = barNumber;
      const xStep = (canvasWidth - 50) / xSections;
      const barWidth = xStep * 0.6;
      const barSpacing = xStep * 0.2;
      const pos = 15;
      let j = 0;
      totalSum = 0;
      totalNumber = 0;

      // Calculate total sum and total number
      fields.forEach((field) => {
        if (myGraphData[field.name] && Number(myGraphData[field.name]) > 0) {
          totalNumber++;
          totalSum += Number(myGraphData[field.name]);
        }
      });

      let lastY = canvasHeight - 10;
      // Draw bars based on the portion
      fields.forEach((field) => {
        if (
          myGraphData[field.name] &&
          Number(myGraphData[field.name]) > 0 &&
          ctx
        ) {
          const portion = Number(myGraphData[field.name]) / totalSum;
          ctx.fillStyle = field.color;
          const x = 50 + pos * xStep + barSpacing;
          const h = portion * (canvasHeight - 20);
          const y = lastY - h;
          ctx.beginPath();
          ctx.fillRect(x, y, barWidth, h);
          ctx.stroke();
          j++;
          lastY = y;
        }
      });
    }
  };

  const drawChart = () => {
    const canvas = canvasRef.current;
    ctx = canvas.getContext("2d");
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    if (ctx) {
      // Draw y-axis with markings
      ctx.beginPath();
      ctx.moveTo(50, 10);
      ctx.lineTo(50, canvasHeight - 10);
      ctx.stroke();

      const yMarks = [0, 0.25, 0.5, 0.75, 1];
      yMarks.forEach((mark) => {
        const y = canvasHeight - 10 - mark * (canvasHeight - 20);
        ctx.moveTo(45, y);
        ctx.lineTo(50, y);
        ctx.stroke();
        ctx.fillText(mark.toString(), 20, y + 3);
      });

      // Draw x-axis with sections
      ctx.beginPath();
      ctx.moveTo(50, canvasHeight - 10);
      ctx.lineTo(canvasWidth, canvasHeight - 10);
      ctx.stroke();

      const xSections = barNumber;
      const xStep = (canvasWidth - 50) / xSections;
      for (let i = 0; i <= xSections; i++) {
        const x = 50 + i * xStep;
        ctx.moveTo(x, canvasHeight - 10);
        ctx.lineTo(x, canvasHeight - 5);
        ctx.stroke();
      }

      // Fetch country graph data
      api
        .get("/country/" + country)
        .then((response) => {
          const countryGraphData = response.data;
          if (countryGraphData) {
            let graphData = countryGraphData;
            let k = 0;
            for (let i = 0; i < barNumber; i++) {
              let sum = 0;
              let yIndex = canvasHeight - 10;
              if (i === 15) {
                k = 1;
                continue;
              }
              for (let data of graphData) {
                let num = Number(data[`U${i + 1 - k}`]);
                if (num > 0) {
                  sum += num;
                  const taxonomy = data["Taxonomy"].toString();

                  ctx.fillStyle = colors[taxonomy] || randomColor(0.5);

                  ctx.fillRect(
                    50 + i * xStep + xStep * 0.2,
                    yIndex - num * (canvasHeight - 20),
                    xStep * 0.6,
                    num * (canvasHeight - 20)
                  );
                  yIndex -= num * (canvasHeight - 20);
                }
              }
            }
          }
        })
        .catch((err) => {
          console.error("Error fetching country graph data:", err);
        });
      loadUserGraph();
    }
  };

  const handleResize = () => {
    if (containerRef.current) {
      const screenWidth = window.innerWidth;
      const width = screenWidth < 1000 ? screenWidth - 20 : 805; // Scale down only below 1000px
      setCanvasWidth(width);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    drawChart();
  }, [canvasWidth, country]);

  const randomColor = (a) => {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  };

  const validFields = fields.filter(
    (field) => myGraphData[field.name] && Number(myGraphData[field.name] > 0)
  );

  return (
    <div className="flex justify-normal items-center" style={{ marginTop: "32px", maxWidth: "805px" }}>
      {/* Canvas Section */}
      <div
        ref={containerRef}
        style={{
          width: "67%", // Takes 70% of the space
          marginRight: "8px",
        }}
      >
        <canvas
          ref={canvasRef}
          style={{ width: "100%", height: "auto" }}
        ></canvas>
      </div>

      {/* Names Section */}
      <div
        className="flex flex-col items-start justify-start"
        style={{
          width: "20%", // Takes 20% of the space
          paddingLeft: "4px",
          paddingRight: "4px",
        }}
      >
        {validFields.map((field, index) => (
          <div
            key={index}
            className="flex items-center justify-start mb-1 p-1 rounded-lg shadow-md bg-white"
          >
            <div
              className="w-2 h-2 mr-1"
              style={{ backgroundColor: field.color }}
            ></div>
            <p className="text-[2px] leading-[2px] m-0">{field.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarChartComponent;
