import styled from "styled-components";

export const ListItemDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border: 1px solid #000;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    background-color: #555555;
    color: #fff;
    cursor: pointer;
`;