import React from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

// Components
import {DashboardInner, DashboardWrapper} from '../../components/DashboardWrapper/DashboardWrapper.styles';
import {FeaturesWrapper} from '../../components/FeaturesContainer/FeaturesContainer.styles';
import DashboardHeader from '../../components/DashboardHeader';
import Feature from '../../components/Feature';
import PatientProfile from '../../components/PatientProfile';

import MicrobiomeIcon from "../../assets/microbiome.svg"
import AddIcon from "../../assets/new-test.svg"

const PatientsCabinetPage = () => {
    const {patientId} = useParams();
    const {t} = useTranslation('common');

    return (
        <DashboardWrapper>
            <DashboardHeader/>
            <DashboardInner>
                <PatientProfile/>
                <FeaturesWrapper>
                    <Feature image={MicrobiomeIcon} text={t('glacies_skin_report')}
                             redirect={`skin-report`} isUrl={false}/>
                    <Feature image={MicrobiomeIcon} text={t('skin_report_files')}
                             redirect={patientId + `/skin-report-files`}/>
                    <Feature image={AddIcon} text={t('order_new_test')} redirect="https://www.glacies.bio"
                             isNewPage={true}/>
                    <Feature image={AddIcon} text={t('update_visual_analysis')} redirect={patientId + "/analysis"}/>
                    <Feature image={AddIcon} text={t('personal_habits')}
                             isNewPage={true}
                             redirect="https://docs.google.com/forms/d/e/1FAIpQLSclXllLWJaPVqF6mxSCFjGVIGfiuLgbxAeetQoEVO127_K-jw/viewform?fbclid=IwZXh0bgNhZW0CMTAAAR0NtO0Ywuygdm8A9CCG_Mu2fSgNigtdp9mv4HIptopyC2fYEibBFmbNO8I_aem_Aapk0qjWDbGQMnVO9qKGv9V0FnbfnNS9b-xfSSjdcjaJ3wUigazKVeD8k3kKkkkXkCjvl0w33K3PrVucymPnKSkW"/>
                </FeaturesWrapper>
            </DashboardInner>
        </DashboardWrapper>
    );

}

export default PatientsCabinetPage;
