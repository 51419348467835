import React, {useState} from "react";
import Accordion from "../../widgets/accardion";
import Osnw from "./skin_indexs/osnw";
import {CSSTransition, TransitionGroup} from "react-transition-group";

const PersonalGrowTab = ({report}) => {

    const [currentTab, setCurrentTab] = useState(0)

    function getTabContent() {
        switch (currentTab) {
            default:
            case 0:
                return <p>
                    Biological skin age expresses the condition of a skin based on various multiple factors, not
                    determined by your chronological age. We assess the health, vitality, and appearance of the skin,
                    influenced by factors such as genetics, lifestyle choices, environmental exposure, and skincare
                    applications. Additionally, we determine your biological skin age by calculating your microbiome
                    diversity, skin hydration, wrinkles, pigmentation, tone, and collagen structure.
                </p>;
            case 1:
                return <p>
                    • A gentle, hydrating cleanser removes impurities without stripping the skin's natural oils, helping
                    to maintain moisture balance in dry skin.
                    <br/>
                    <br/>
                    • Since the Firmicutes bacteria on your face are below normal levels, gentler and alcohol-free
                    cleansers are more suitable for you.
                </p>

            case 2:
                return <p>
                    • Use first thing in the morning and before sleep.
                </p>
        }
    }

    function selectTab(tab) {
        setCurrentTab(tab);
    }

    return (
        <div className="flex flex-wrap gap-3 m-auto vertical-layout" style={{maxWidth: "1300px"}}>
            <div className="flex flex-col gap-3 flex-1">
                <Accordion
                    title={"Your Skin Index"}
                    rightAction={
                        <h1 className="font-bold">{(report['data']['skin_index'] || '').toUpperCase()}</h1>
                    }
                    children={
                        <Osnw/>
                    }
                >

                </Accordion>
            </div>
            <div className="flex flex-col gap-3 flex-1">
                <Accordion
                    title={"Your Personalized Skincare Routine"}
                    children={
                        <div className="flex flex-col gap-3 items-center">
                            <div>Product image</div>
                            <div className="flex flex-col items-center gap-3 w-full">
                                <div>
                                    <div className="flex flex-row gap-3 p-3 tab-widget-buttons">
                                        <button
                                            className={currentTab === 0 ? 'selected' : ''}
                                            onClick={() => selectTab(0)}
                                        >
                                            Info
                                        </button>
                                        <button
                                            className={currentTab === 1 ? 'selected' : ''}
                                            onClick={() => selectTab(1)}
                                        >
                                            Why?
                                        </button>
                                        <button
                                            className={currentTab === 2 ? 'selected' : ''}
                                            onClick={() => selectTab(2)}
                                        >
                                            How?
                                        </button>
                                    </div>
                                </div>
                                <TransitionGroup className="content-wrapper w-full">
                                    <CSSTransition
                                        key={currentTab}
                                        classNames="content"
                                        timeout={500}
                                    >
                                        {
                                            <div className="w-full">
                                                {getTabContent(currentTab, report)}
                                            </div>
                                        }
                                    </CSSTransition>
                                </TransitionGroup>

                            </div>
                        </div>
                    }
                >
                </Accordion>
            </div>
        </div>
    );
}

export default PersonalGrowTab;